import {
  ref,
} from 'vue'

const STORAGE_KEY_SIGNED_IN = 'signed_in'

const signedIn = ref()

export const useAuthentication = () => {
  const isSignedIn = () => signedIn.value = localStorage.getItem(STORAGE_KEY_SIGNED_IN) ? true : false

  const signedInToStorage = () => {
    localStorage.setItem(STORAGE_KEY_SIGNED_IN, 1)
    signedIn.value = true
  }

  const signedOutToStorage = () => {
    localStorage.removeItem(STORAGE_KEY_SIGNED_IN)
    signedIn.value = false
  }

  isSignedIn()

  return {
    // variables
    signedIn,
    // methods
    isSignedIn,
    signedInToStorage,
    signedOutToStorage,
  }
}
